import { gameQuery } from '@/state/game/game.query';
import { cardsQuery } from '@/state/cards/cards.query';
import { moveHistory } from '@/core/move-history';
import { cardsService } from '@/state/cards/cards.service';
import { Card, CardOwner, GameStatus, Suit } from '@/core/models';
import { BaseGameController } from '@/core/base-game.controller';
import { coreBus } from '@/core/core-bus';
import { range } from 'lodash';

export class GameController extends BaseGameController {
    constructor() {
        super();
        this.subscription.add(
            gameQuery.gameStatus$.subscribe((status) => {
                if (status == GameStatus.dealCompleted) {
                    if (process.env.NODE_ENV === 'development') {
                        // setTimeout(() => this.setCustomBoard(), 1500);
                    }
                }
            })
        );
        this.initStage();
    }

    private initStage() {
        const suits = [Suit.Diamond, Suit.Spade, Suit.Club, Suit.Heart];
        const cards = suits.flatMap((s) => {
            return range(1, 14).map(
                (r) =>
                    ({
                        id: `${r}${s}`,
                        suit: s,
                        rank: r,
                        order: 0,
                        dragEnabled: false,
                        owner: CardOwner.stock,
                    } as Card)
            );
        });
        cardsService.init(cards);
    }

    protected initGame(): void {
        cardsService.resetAllCards(CardOwner.stock);
        moveHistory.reset();
    }

    protected isGameCompleted(): boolean {
        return cardsQuery.getAllByOwner(CardOwner.foundation).length == 52;
    }

    private setCustomBoard() {
        /* eslint-disable */
        const f1 = ['1H', '2H', '3H', '4H', '5H', '6H', '7H', '8H', '9H'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 1,
                order: i + 1
            };
        });
        const f2 = ['1S', '2S', '3S', '4S', '5S', '6S', '7S', '8S', '9S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 2,
                order: i + 1
            };
        });
        const f3 = ['1D', '2D', '3D', '4D', '5D', '6D', '7D', '8D', '9D'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 3,
                order: i + 1
            };
        });
        const f4 = ['1C', '2C', '3C', '4C', '5C', '6C', '7C', '8C', '9C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.foundation,
                ownerIndex: 4,
                order: i + 1
            };
        });
        const t1 = ['13H', '12S', '11H', '10S'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 1,
                order: i + 1
            };
        });
        const t2 = ['13S', '12H', '11S', '10H'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i + 1
            };
        });
        const t3 = ['13D', '12C', '11D', '10C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i + 1
            };
        });
        const t4 = ['13C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i + 1
            };
        });
        const t5 = ['10D', '12D', '11C'].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: id != '10D',
                dragEnabled: id != '10D',
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i + 1
            };
        });
        const t6 = [].map((id, i) => {
            const card = cardsQuery.getEntity(id);
            return {
                ...card!,
                isFaceUp: true,
                dragEnabled: true,
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i + 1
            };
        });

        const all = f1
            .concat(f2)
            .concat(f3)
            .concat(f4)
            .concat(t1)
            .concat(t2)
            .concat(t3)
            .concat(t4)
            .concat(t5); //.concat(t6);
        cardsService.upsertMany(all);
        all.forEach(c => {
            coreBus.cardMoveCmd$.next({
                cardId: c.id,
                duration: 0
            });
        });
    }
}
