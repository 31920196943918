<template>
    <div>
        <empty-frame :style="tableau1Style" :highlight="tableauHighlight === 1"></empty-frame>
        <empty-frame :style="tableau2Style" :highlight="tableauHighlight === 2"></empty-frame>
        <empty-frame :style="tableau3Style" :highlight="tableauHighlight === 3"></empty-frame>
        <empty-frame :style="tableau4Style" :highlight="tableauHighlight === 4"></empty-frame>
        <empty-frame :style="tableau5Style" :highlight="tableauHighlight === 5"></empty-frame>
        <empty-frame :style="tableau6Style" :highlight="tableauHighlight === 6"></empty-frame>
        <empty-frame :style="tableau7Style" :highlight="tableauHighlight === 7"></empty-frame>

        <card-stock :style="stockStyle" :enable-recycle="true" />

        <suit-frame suit="heart" :style="foundation1Style" :highlight="foundationHighlight === 1" />
        <suit-frame suit="spade" :style="foundation2Style" :highlight="foundationHighlight === 2" />
        <suit-frame
            suit="diamond"
            :style="foundation3Style"
            :highlight="foundationHighlight === 3"
        />
        <suit-frame suit="club" :style="foundation4Style" :highlight="foundationHighlight === 4" />
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { CardOwner } from '@/core/models';
import { gameQuery } from '@/state/game/game.query';
import { Display } from '@/games/klondike/display';
import { cardsQuery } from '@/state/cards/cards.query';
import { bus } from '@/games/klondike/bus';
import { subscribeTo, useObservable } from '@/core/rxjs-helpers';
import SuitFrame from '@/components/SuitFrame.vue';
import EmptyFrame from '@/components/EmptyFrame.vue';
import CardStock from '@/components/CardStock.vue';
import { display } from '@/games/klondike/display';
import { recomputable } from '@/composable/recomputable';

export default defineComponent({
    components: { SuitFrame, EmptyFrame, CardStock },

    setup() {
        const foundationHighlight = useObservable(gameQuery.foundationIndexHighlight$);
        const tableauHighlight = useObservable(gameQuery.tableauIndexHighlight$);
        const recompute = ref(0);

        subscribeTo(gameQuery.gameSize$, () => {
            setTimeout(() => {
                recompute.value += 1;
            });
        });

        const stockClick = () => {
            if (cardsQuery.getAllByOwner(CardOwner.stock).length == 0) {
                bus.recycleWasteCmd$.next();
            } else {
                bus.stockClickEvent$.next();
            }
        };

        const getFrameStyle = (x: number, y: number) => {
            return {
                top: `${y}px`,
                left: `${x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
                borderRadius: `${display.cardSize.width * 0.06}px`,
            };
        };

        const tableau1Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau1Position.x, display.tableau1Position.y);
        });

        const tableau2Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau2Position.x, display.tableau2Position.y);
        });

        const tableau3Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau3Position.x, display.tableau3Position.y);
        });

        const tableau4Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau4Position.x, display.tableau4Position.y);
        });

        const tableau5Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau5Position.x, display.tableau5Position.y);
        });

        const tableau6Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau6Position.x, display.tableau6Position.y);
        });

        const tableau7Style = recomputable(recompute, () => {
            return getFrameStyle(display.tableau7Position.x, display.tableau7Position.y);
        });

        const stockStyle = recomputable(recompute, () => {
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
            };
        });

        const restartIconStyle = recomputable(recompute, () => {
            const s = display.cardSize.width * 0.4;
            return {
                height: `${s}px`,
                width: `${s}px`,
            };
        });

        const getFoundation = (display: Display) => {
            return {
                lineHeight: `${display.cardSize.height}px`,
                fontSize: `${display.cardSize.height * 0.3}px`,
            };
        };

        const foundation1Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation1Position.x,
                display.foundation1Position.y
            );
            return {
                ...frame,
                ...getFoundation(display),
            };
        });

        const foundation2Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation2Position.x,
                display.foundation2Position.y
            );
            return {
                ...frame,
                ...getFoundation(display),
            };
        });

        const foundation3Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation3Position.x,
                display.foundation3Position.y
            );
            return {
                ...frame,
                ...getFoundation(display),
            };
        });

        const foundation4Style = recomputable(recompute, () => {
            const frame = getFrameStyle(
                display.foundation4Position.x,
                display.foundation4Position.y
            );
            return {
                ...frame,
                ...getFoundation(display),
            };
        });

        const stockIconStyle = recomputable(recompute, () => {
            return {
                fontSize: `${display.cardSize.width * 0.4}px`,
            };
        });

        const stockOverlayStyle = recomputable(recompute, () => {
            return {
                top: `${display.stockPosition.y}px`,
                left: `${display.stockPosition.x}px`,
                width: `${display.cardSize.width}px`,
                height: `${display.cardSize.height}px`,
            };
        });

        return {
            foundationHighlight,
            stockClick,
            tableau1Style,
            tableau2Style,
            tableau3Style,
            tableau4Style,
            tableau5Style,
            tableau6Style,
            tableau7Style,
            stockStyle,
            restartIconStyle,
            foundation1Style,
            foundation2Style,
            foundation3Style,
            foundation4Style,
            stockIconStyle,
            stockOverlayStyle,
            tableauHighlight,
        };
    },
});
</script>

<style scoped>
.frame {
    border: #777 1px solid;
    position: absolute;
    background-color: rgba(50, 50, 50, 0.5);
    pointer-events: none;
}
.frame.clickable {
    pointer-events: all;
    cursor: pointer;
}
.frame.stock {
    text-align: center;
    vertical-align: center;
}
.stock-overlay {
    position: absolute;
    z-index: 99;
    cursor: pointer;
    pointer-events: all;
}
</style>
