import { Card, CardOwner } from '@/core/models';
import { cardsService } from '@/state/cards/cards.service';
import { cardsQuery } from '@/state/cards/cards.query';
import { judge } from '@/games/klondike/judge';
import { moveHistory } from '@/core/move-history';
import { gameService } from '@/state/game/game.service';
import { coreBus } from '@/core/core-bus';
import { CardMoveBaseController } from '@/core/card-move-base.controller';

export class MoveController extends CardMoveBaseController {
    constructor() {
        super({
            enableTableauAutoAdjust: true,
            meldCard: {
                validate: (cmd) => judge.canPutCardOnTopOf(cmd.card, cmd.destCard),
                after: (cmd) => this.reorganizeWasteAfterMoveCardFromWaste(cmd.card),
            },
            moveCardToFoundation: {
                validate: (cmd) => judge.canPutInFoundation(cmd.card, cmd.foundationIndex),
                after: (cmd) => this.reorganizeWasteAfterMoveCardFromWaste(cmd.card),
            },
            moveCardToEmptyTableauCmd: {
                validate: (cmd) =>
                    judge.canMoveToSpecificTableauEmptyFrame(cmd.card, cmd.tableauIndex),
                after: (cmd) => this.reorganizeWasteAfterMoveCardFromWaste(cmd.card),
            },
        });
        this.subscription.add(
            coreBus.recycleWasteCmd$.subscribe(() => {
                this.recycleWaste();
                coreBus.gameMoveCompletedEvent$.next();
            })
        );
    }

    private reorganizeWasteAfterMoveCardFromWaste(cardMovedFromWaste: Card) {
        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 1) {
            return;
        }

        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 2) {
            cardsQuery
                .getAll()
                .filter((c) => c.owner == CardOwner.waste && c.ownerIndex == 1)
                .forEach((c) => {
                    cardsService.update(c.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(c);
                });
            return;
        }

        if (cardMovedFromWaste.owner == CardOwner.waste && cardMovedFromWaste.ownerIndex == 3) {
            const cardsInWaste1 = cardsQuery
                .getAll()
                .filter((c) => c.owner == CardOwner.waste && c.ownerIndex == 1);
            if (cardsInWaste1.length > 1) {
                const cw2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
                if (cw2) {
                    cardsService.update(cw2.id, {
                        owner: CardOwner.waste,
                        ownerIndex: 3,
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw2);
                    coreBus.cardMoveCmd$.next({
                        cardId: cw2.id,
                        duration: 300,
                    });
                }

                const cw1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
                if (cw1) {
                    cardsService.update(cw1.id, {
                        owner: CardOwner.waste,
                        ownerIndex: 2,
                    });
                    moveHistory.addState(cw1);
                    coreBus.cardMoveCmd$.next({
                        cardId: cw1.id,
                        duration: 300,
                    });
                }
            } else {
                const cw3 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 3);
                if (cw3) {
                    cardsService.update(cw3.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw3);
                    return;
                }

                const cw2 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 2);
                if (cw2) {
                    cardsService.update(cw2.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw2);
                    return;
                }

                const cw1 = cardsQuery.getTopByOwnerAndIndex(CardOwner.waste, 1);
                if (cw1) {
                    cardsService.update(cw1.id, {
                        dragEnabled: true,
                    });
                    moveHistory.addState(cw1);
                    return;
                }
            }
        }
    }

    private recycleWaste() {
        if (!this.canMakeMove()) {
            return;
        }

        const cards = cardsQuery.getAllByOwner(CardOwner.waste);
        if (cards.length == 0) {
            return;
        }

        moveHistory.startMove();

        const updates = cards.map((c) => {
            moveHistory.addState(c);
            return {
                ...c,
                owner: CardOwner.stock,
                isFaceUp: false,
                order: cards.length - c.order + 1,
                dragEnabled: false,
                isShadow: false,
                clickEnabled: cards.length == cards.length - c.order + 1,
            };
        });
        cardsService.upsertMany(updates);
        cardsQuery.getAllByOwner(CardOwner.stock).forEach((c) => {
            coreBus.cardMoveCmd$.next({
                duration: 300,
                cardId: c.id,
            });
        });

        gameService.increaseMoveCounter();
        moveHistory.endMove();
    }
}
