
import { defineComponent, computed } from 'vue';
import { controllers } from '@/games/klondike/controllers/controllers';
import Frames from '@/games/klondike/views/Frames.vue';
import CardGameStage from '@/components/CardGameStage.vue';
import { display } from '@/games/klondike/display';

export default defineComponent({
    components: {
        Frames,
        CardGameStage,
    },

    setup() {
        const controllersInst = computed(() => controllers);
        const displayInst = computed(() => display);

        return {
            controllersInst,
            displayInst,
        };
    },
});
