import { cardsQuery } from '@/state/cards/cards.query';
import { cardsService } from '@/state/cards/cards.service';
import { CardOwner } from '@/core/models';
import { coreBus } from '@/core/core-bus';
import { CardDealerBaseController } from '@/core/card-dealer.base.controller';

export class DealerController extends CardDealerBaseController {
    constructor() {
        super(30, 28);
    }

    protected dealCard(i: number) {
        const card = cardsQuery.getTopByOwner(CardOwner.stock);
        if (!card) {
            throw new Error('no card in stock');
        }

        // tableau 1
        if (i == 1) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 1,
                isFaceUp: true,
                order: 1,
                dragEnabled: true,
            });
        }

        // tableau 2
        if (i >= 2 && i <= 3) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 2,
                order: i - 1,
                isFaceUp: i == 3,
                dragEnabled: i == 3,
            });
        }

        // tableau 3
        if (i >= 4 && i <= 6) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 3,
                order: i - 3,
                isFaceUp: i == 6,
                dragEnabled: i == 6,
            });
        }

        // tableau 4
        if (i >= 7 && i <= 10) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 4,
                order: i - 6,
                isFaceUp: i == 10,
                dragEnabled: i == 10,
            });
        }

        // tableau 5
        if (i >= 11 && i <= 15) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 5,
                order: i - 10,
                isFaceUp: i == 15,
                dragEnabled: i == 15,
            });
        }

        // tableau 6
        if (i >= 16 && i <= 21) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 6,
                order: i - 15,
                isFaceUp: i == 21,
                dragEnabled: i == 21,
            });
        }

        // tableau 7
        if (i >= 22 && i <= 28) {
            cardsService.update(card.id, {
                owner: CardOwner.tableau,
                ownerIndex: 7,
                order: i - 21,
                isFaceUp: i == 28,
                dragEnabled: i == 28,
            });
        }

        coreBus.cardMoveCmd$.next({
            duration: 0,
            cardId: card.id,
        });
    }
}
