import { Card, CardOwner, Position } from '@/core/models';
import { CardDisplayBase } from '@/core/card-display.base';

export class Display extends CardDisplayBase {
    stockPosition: Position = { x: 0, y: 0 };
    tableau1Position: Position = { x: 0, y: 0 };
    tableau2Position: Position = { x: 0, y: 0 };
    tableau3Position: Position = { x: 0, y: 0 };
    tableau4Position: Position = { x: 0, y: 0 };
    tableau5Position: Position = { x: 0, y: 0 };
    tableau6Position: Position = { x: 0, y: 0 };
    tableau7Position: Position = { x: 0, y: 0 };
    foundation1Position: Position = { x: 0, y: 0 };
    foundation2Position: Position = { x: 0, y: 0 };
    foundation3Position: Position = { x: 0, y: 0 };
    foundation4Position: Position = { x: 0, y: 0 };
    wastePosition: Position = { x: 0, y: 0 };

    constructor() {
        super(7);
    }

    calcDisplay() {
        const cardWidth = this.cardSize.width;
        const cardHeight = this.cardSize.height;
        const spaceSize = this.spaceHorizontal;

        const tableauY = cardHeight + spaceSize * 2;

        this.tableau1Position = {
            x: 0,
            y: tableauY,
        };

        this.tableau2Position = {
            x: cardWidth + spaceSize,
            y: tableauY,
        };

        this.tableau3Position = {
            x: cardWidth * 2 + spaceSize * 2,
            y: tableauY,
        };

        this.tableau4Position = {
            x: cardWidth * 3 + spaceSize * 3,
            y: tableauY,
        };

        this.tableau5Position = {
            x: cardWidth * 4 + spaceSize * 4,
            y: tableauY,
        };

        this.tableau6Position = {
            x: cardWidth * 5 + spaceSize * 5,
            y: tableauY,
        };

        this.tableau7Position = {
            x: cardWidth * 6 + spaceSize * 6,
            y: tableauY,
        };

        this.stockPosition = {
            x: this.tableau7Position.x,
            y: 0,
        };

        this.foundation1Position = {
            x: this.tableau1Position.x,
            y: this.stockPosition.y,
        };

        this.foundation2Position = {
            x: this.tableau2Position.x,
            y: this.stockPosition.y,
        };

        this.foundation3Position = {
            x: this.tableau3Position.x,
            y: this.stockPosition.y,
        };

        this.foundation4Position = {
            x: this.tableau4Position.x,
            y: this.stockPosition.y,
        };

        this.wastePosition = {
            x: this.tableau6Position.x,
            y: this.stockPosition.y,
        };
    }

    getTableauPosition(ownerIndex: number) {
        if (ownerIndex === 1) {
            return this.tableau1Position;
        }
        if (ownerIndex === 2) {
            return this.tableau2Position;
        }
        if (ownerIndex === 3) {
            return this.tableau3Position;
        }
        if (ownerIndex === 4) {
            return this.tableau4Position;
        }
        if (ownerIndex === 5) {
            return this.tableau5Position;
        }
        if (ownerIndex === 6) {
            return this.tableau6Position;
        }
        if (ownerIndex === 7) {
            return this.tableau7Position;
        }
        throw new Error('invalid owner');
    }

    getFoundationPosition(ownerIndex: number) {
        if (ownerIndex === 1) {
            return this.foundation1Position;
        }
        if (ownerIndex === 2) {
            return this.foundation2Position;
        }
        if (ownerIndex === 3) {
            return this.foundation3Position;
        }
        if (ownerIndex === 4) {
            return this.foundation4Position;
        }
        throw new Error('invalid owner');
    }

    calcCardPosition(card: Card): Position {
        if (card.owner == CardOwner.tableau) {
            const tabPos = this.getTableauPosition(card.ownerIndex);
            const space = this.getTableauVerticalSpace(tabPos.y, card.ownerIndex);
            return {
                x: tabPos.x,
                y: tabPos.y + (card.order - 1) * space,
            };
        }
        if (card.owner == CardOwner.stock) {
            return {
                ...this.stockPosition,
            };
        }

        const drawSpace = this.cardSize.width * 0.45;
        if (card.owner == CardOwner.waste && card.ownerIndex == 1) {
            return {
                x: this.wastePosition.x - 2 * drawSpace,
                y: this.wastePosition.y,
            };
        }
        if (card.owner == CardOwner.waste && card.ownerIndex == 2) {
            return {
                x: this.wastePosition.x - drawSpace,
                y: this.wastePosition.y,
            };
        }
        if (card.owner == CardOwner.waste && card.ownerIndex == 3) {
            return {
                x: this.wastePosition.x,
                y: this.wastePosition.y,
            };
        }

        if (card.owner == CardOwner.foundation) {
            const foundPos = this.getFoundationPosition(card.ownerIndex);
            return {
                x: foundPos.x,
                y: foundPos.y,
            };
        }

        throw new Error(`could not calc position, id: ${card.id}: owner: ${card.owner}`);
    }
}

export const display = new Display();
