import { Card, CardOwner, Hint } from '@/core/models';
import { judge } from '@/games/klondike/judge';
import { cardsQuery } from '@/state/cards/cards.query';
import { orderBy } from 'lodash';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { coreBus } from '@/core/core-bus';
import { CardAutoMoveBaseController } from '@/core/card-auto-move.base.controller';
import { coreUtil } from '@/core/core-util';
import { cardSound } from '@/core/sound';

export class AutoMoveController extends CardAutoMoveBaseController {
    protected doBestMove(card: Card) {
        if (card.owner == CardOwner.waste || card.owner == CardOwner.tableau) {
            // safe guard check if card is face up
            if (!card.isFaceUp) {
                return;
            }

            // try to move card to foundation
            if (judge.canPutInFoundation(card, coreUtil.getFoundation4IndexForSuit(card.suit))) {
                coreBus.moveCardToFoundationCmd$.next({
                    card,
                    foundationIndex: coreUtil.getFoundation4IndexForSuit(card.suit),
                });
                return;
            }

            // try to meld card
            const tableauTops = cardsQuery.getTopTableauCards();
            for (let i = 0; i < tableauTops.length; i++) {
                const c = tableauTops[i];
                if (judge.canPutCardOnTopOf(card, c)) {
                    coreBus.meldCardCmd$.next({
                        card,
                        destCard: c,
                    });
                    return;
                }
            }

            // try to move king card to empty tableau
            for (let i = 1; i <= 7; i++) {
                if (judge.canMoveToSpecificTableauEmptyFrame(card, i)) {
                    coreBus.moveCardToEmptyTableauCmd$.next({
                        card,
                        tableauIndex: i,
                    });
                    return;
                }
            }

            // if we got here we could find any auto move
            // we want to shake the card
            coreBus.shakeCardCmd$.next({
                card,
            });
        }
    }

    protected isAutoFinishPossible() {
        // if all cards in tableau or foundation and all cards face up
        // then we can auto finish
        const cards = cardsQuery.getAll();
        for (let i = 0; i < cards.length; i++) {
            const card = cards[i];
            if (!(card.owner == CardOwner.tableau || card.owner == CardOwner.foundation)) {
                return false;
            }
            if (!card.isFaceUp) {
                return false;
            }
        }
        return true;
    }

    protected doAutoFinish(done: () => void) {
        console.log('----auto move');
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau);
        const ordered = orderBy(tabCards, (c) => c.rank);

        const sub = interval(100)
            .pipe(take(ordered.length))
            .subscribe({
                next: (i) => {
                    const card = ordered[i];
                    if (
                        judge.canPutInFoundation(
                            card,
                            coreUtil.getFoundation4IndexForSuit(card.suit)
                        )
                    ) {
                        coreBus.moveCardToFoundationCmd$.next({
                            card,
                            foundationIndex: coreUtil.getFoundation4IndexForSuit(card.suit),
                        });
                    }
                },
                complete: () => {
                    done();
                },
            });

        this.subscription.add(sub);
    }

    protected generateHints() {
        const tableauTops = cardsQuery.getTopTableauCards();
        const tabCards = cardsQuery.getAllByOwner(CardOwner.tableau).filter((c) => c.isFaceUp);
        const foundTops = cardsQuery.getTopCardsByOwner(CardOwner.foundation);
        const wasteTop = cardsQuery
            .getTopCardsByOwner(CardOwner.waste)
            .filter((c) => c.dragEnabled);

        // hints for tableau
        const tabHints = tabCards
            .concat(foundTops)
            .concat(wasteTop)
            .flatMap((c) => {
                return tableauTops
                    .filter((tc) => tc.ownerIndex != c.ownerIndex && judge.canPutCardOnTopOf(c, tc))
                    .map(
                        (tc) =>
                            ({
                                card1Id: c.id,
                                card2Id: tc.id,
                                priority: 0,
                            } as Hint)
                    );
            });

        // hints for foundation
        const foundHints = tableauTops
            .concat(wasteTop)
            .filter((c) => judge.canPutInFoundation(c, coreUtil.getFoundation4IndexForSuit(c.suit)))
            .map((c) => {
                const foundIndex = coreUtil.getFoundation4IndexForSuit(c.suit);
                const foundCard = cardsQuery.getTopByOwnerAndIndex(
                    CardOwner.foundation,
                    foundIndex
                );
                return {
                    card1Id: c.id,
                    card2Id: foundCard ? foundCard.id : null,
                    foundationIndex: foundCard ? null : foundIndex,
                } as Hint;
            });

        // empty tableau
        const emptyTabHints = tabCards.concat(wasteTop).flatMap((c) => {
            return [1, 2, 3, 4, 5, 6, 7]
                .filter((t) => judge.canMoveToSpecificTableauEmptyFrame(c, t))
                .map(
                    (t) =>
                        ({
                            card1Id: c.id,
                            tableauIndex: t,
                        } as Hint)
                );
        });

        return tabHints.concat(foundHints).concat(emptyTabHints);
    }
}
